import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import { getDeviceList_returns_data_item as Device } from "../api/getDeviceList";


export interface IInputState {
    device: Device|null;
    selectedDate: Moment;
    selectedHour: number;
}

const InitialInputState: IInputState = {
    device: null,
    selectedDate: moment(),
    selectedHour: moment().subtract(1, 'hour').hour()
}

export const InputSlice = createSlice({
  name: "input",
  initialState: InitialInputState,
  reducers: {
    changeDevice: (state: IInputState, action: PayloadAction<Device>) => {
      state.device = action.payload;
      return state;
    },
    changeSelectedDate: (state: IInputState, action: PayloadAction<Moment>) => {
      state.selectedDate = action.payload;
      return state;
    },
    changeSelectedHour: (state: IInputState, action: PayloadAction<number>) => {
      state.selectedHour = action.payload;
      return state;
    },
  },
});

export const { changeDevice,changeSelectedDate,changeSelectedHour } = InputSlice.actions;
export const InputReducer = InputSlice.reducer;